@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'),
  url('../fonts/Raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/Raleway/Raleway-Regular.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/Raleway/Raleway-Regular.woff') format('woff'), /* Pretty Modern Browsers */
     url('../fonts/Raleway/Raleway-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('../fonts/Raleway/Raleway-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 400;
  font-style:'normal';
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat'),
  url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'), /* Pretty Modern Browsers */
     url('../fonts/Montserrat/Montserrat-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('../fonts/Montserrat/Montserrat-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 400;
  font-style:'normal';
}

a, h1,h2,h3,h4,h5,p,div {
  font-family :'Montserrat';
}

.container-area{
  padding : 0 15px;
}
