.tempPayImage{
  object-fit:cover;
  width:100%;
  height:100%;
  vertical-align: top;
}

.outsidePayButton{
  position: fixed;
  bottom: 0px;
  margin : 0 auto;
  width: 100%;
  border-radius:0px;
  -moz-border-radius:0px;
  -webkit-border-radius:0px;
}