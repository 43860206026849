.animated {
  animation-duration: 1s;
  -webkit-animation-duration:1s;
  animation-fill-mode:both;
  -webkit-animation-fill-mode:both;
}

.animated.infinite {
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.animated.delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

.animated.delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

.animated.delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}

.animated.delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
}

.animated.delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}

.animated.fast {
  animation-duration: 800ms;
  -webkit-animation-duration:800ms;
}

.animated.faster {
  animation-duration: 500ms;
  -webkit-animation-duration:500ms;
}

.animated.slow {
  animation-duration: 2s;
  -webkit-animation-duration:2s;
}

.animated.slower {
  animation-duration: 3s;
  -webkit-animation-duration:3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    -webkit-animation-duration:1ms !important;
    -webkit-transition-duration: 1ms !important;
    -webkit-animation-iteration-count : 1ms !important;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
}
